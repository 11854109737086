<template>
  <floating-order v-if="isOrderVisible" @close="closeOrder" ref="floatingOrder" class="floating-order"></floating-order>
  <floating-basket @click="toggleOrderVisibility"></floating-basket>
  <div class="bock1">
    <video class="video" playsinline autoplay muted>
      <source src="../public/img/videoback.mp4" type="video/mp4"/>
    </video>
    <video class="videoM" playsinline autoplay muted>
      <source src="../public/img/videbackM.mp4" type="video/mp4"/>
    </video>
    <div class="content_overlay">
      <div class="header_block" id="overlay">
        <div class="nav">
          <p style="visibility: hidden">ГЛАВНАЯ</p>
          <a href="#about_us"><p>О НАС</p></a>
          <p @click="goToOrders">КОРЗИНА</p>
        </div>
      </div>
    </div>
  </div>

  <div class="bock2">
    <div class="blocks">
      <div class="centring_title">
        <div class="titling">
          <p class="big_title">Шоколад</p>
          <p class="lit_title">Насыщенный вкус и богатый аромат темного?
            Кремовая текстура и мягкий вкус молочного?
            Сливочная ежность и приятная сладость белого?
            Открой для себя уникальное сочетание
            шоколада с ягодами, орехами или специями</p>
          <p class="lit_title_m">
            Вкусы темного, молочного и белого шоколада, дополненные ягодами, орехами или специями
          </p>
          <button @click="scrollToBlock('chklt')" class="bt2blk">Выбрать</button>
        </div>
      </div>
      <div class="photo_block1"></div>
    </div>

    <div class="blocks">
      <div class="photo_block2"></div>
      <div class="centring_title">
        <div class="titling">
          <p class="big_title">Свечи</p>
          <p class="lit_title">Погрузитесь в атмосферу гармонии и уюта
            с нашими ароматическими свечами.
            Каждая из них наполнена приятными ароматами
            разной сложности для самых глубоких эмоций.
            Дополните ваш интерьер эстетикой
            минималистичного дизайна.</p>
          <p class="lit_title_m">
            Создайте уютную атмосферу с нашими ароматическими свечами.
            Приятные ароматы и минималистичный дизайн дополнят ваш интерьер.
          </p>
          <button @click="scrollToBlock('svechi')" class="bt2blk">Выбрать</button>
        </div>
      </div>
    </div>

    <div id="presents" class="blocks">
      <div class="centring_title">
        <div class="titling">
          <p class="big_title">Подарочные наборы</p>
          <p class="lit_title">Поможем вам порадовать близкого человека,
            выразить благодарность или оказать
            мимолетный знак внимания.
            Подберем для вас сочетание вкусов и ароматов,
            элегантную упаковку и поздравительные слова.
            Позаботимся о том, чтобы сберечь ваше время!</p>
        </div>
      </div>
      <div class="photo_block1"></div>
    </div>

  </div>

  <div class="bock3">
    <p class="assort_ttl">Ассортимент</p>
    <p class="rzdl">ШОКОЛАД</p>
    <div class="content_wrapper" id="chklt">
      <div class="content_container" ref="chocolateContainer">
        <productCard
            v-for="(card, index) in cardsChocolate"
            :key="card.category"
            :cardData="card"
            :class="{ 'active-card': index === currentIndexChocolate }"
        />
      </div>
      <button class="leftscroll" @click="scrollToBack('chocolate')"><img src="../public/img/leftarrow.svg"></button>
      <button class="rightscroll" @click="scrollToNext('chocolate')"><img src="../public/img/rightarrow.svg"></button>
    </div>

    <p class="rzdl">СВЕЧИ</p>
    <div id="svechi" class="content_wrapper">
      <div class="content_container" ref="svechiContainer">
        <productCard
            v-for="(card, index) in cardsSvechi"
            :key="card.category"
            :cardData="card"
            :class="{ 'active-card': index === currentIndexSvechi }"
        />
      </div>
      <button class="leftscroll" @click="scrollToBack('svechi')"><img src="../public/img/leftarrow.svg"></button>
      <button class="rightscroll" @click="scrollToNext('svechi')"><img src="../public/img/rightarrow.svg"></button>
    </div>
  </div>

  <div class="bock4">
    <div class="otziv">
      <p class="big_title">Ваше мнение</p>
      <p>Эта форма обратной связи создана для того, чтобы вы могли вы могли легко и быстро связаться с нами.</p>
      <p> Мы ценим индивидуальный подход к каждому и всегда готовы помочь!</p>
      <p>Ваши вопросы, предложения и отзывы очень важны для нас.</p>
    </div>

    <div class="inputs">
      <input v-model="opinionData.name" class="thin_input" placeholder="Ваше имя">
      <input v-model="opinionData.phone" class="thin_input" placeholder="Номер телефона">
      <textarea v-model="opinionData.wishes" class="fat_input" placeholder="Ваши пожелания"></textarea>
      <div class="agree"><input v-model="check" id="agr" type="checkbox">
        <p>СОГЛАСЕН НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</p></div>
      <button @click=sendWishes class="send_button">ОТПРАВИТЬ</button>
      <div v-if="showerror" class="wisherror">{{ wish_err }}</div>
    </div>

  </div>

  <div class="bock5">
    <div class="abt_ttl">
      <p id="about_us" class="big_title" style="margin: 40px 0 40px 0">О НАС</p>
      <p>Мы творческие интересные созидательные личности, и мы объединились
        для того, чтобы каждый ваш день наполнить эстетикой и роскошью!</p>
      <p>Разрушаем стереотипы “Это что-то на богатом” и делимся с вами возможностью
        наслаждаться качеством, экологичностью и эстетикой нашей продукции.
        Присоединяйтесь к нашему сообществу в канале Телеграмма</p>
    </div>
    <div class="end_img"></div>
  </div>
</template>

<script>
import ProductCard from "@/components/productCard.vue";
import {CardAPI} from "@/api/CardAPI";
import FloatingBasket from "@/components/floatingBasket.vue";
import FloatingOrder from "@/components/floatingOrder.vue";
import {useRouter} from "vue-router";

export default {
  name: 'HomePage',
  components: {
    FloatingBasket,
    ProductCard,
    FloatingOrder
  },
  data() {
    return {
      opinionData: {
        name: "",
        phone: "",
        wishes: ""
      },
      wish_err: "",
      showerror: false,
      check: false,
      isBasketVisible: false,
      isOrderVisible: false,
      cardsChocolate: [],
      cardsSvechi: [],
      currentIndexChocolate: 0,
      currentIndexSvechi: 0,
      currentPage: 1
    };
  },
  setup() {
    const router = useRouter();
    const goToOrders = () => {
      router.push({name: 'Orders'});
    };

    return {goToOrders};
  },
  async created() {
    try {
      const response = await CardAPI.getCard(this.currentPage, 100); // Делаем запрос к API
      this.cardsChocolate = response.cards.filter(card => card.category === 'Шоколад');
      this.cardsSvechi = response.cards.filter(card => card.category === 'Свечи'); // Сохраняем массив карточек
    } catch (error) {
      console.error("Ошибка при получении данных карточек:", error);
    }
  },
  methods: {
    sendWishes() {
      console.log(this.check)
      if (this.opinionData.name === "" || this.opinionData.phone === "" || this.opinionData.wishes === "" || !this.check) {
        this.showerror = true;
        setTimeout(() => (this.showerror = false), 2000);
        this.wish_err = "Пожалуйста, заполните все поля, и подтвердите согласие на обработку";
        return;
      }
      //логика отправки
      this.showerror = true;
      setTimeout(() => (this.showerror = false), 2000);
      this.wish_err = "Спасибо за отзыв!";
      this.opinionData = {name: "", phone: "", wishes: ""};
      this.check = false;
    },
    toggleOrderVisibility() {
      this.isOrderVisible = !this.isOrderVisible;
    },
    closeOrder() {
      this.isOrderVisible = false;
    },
    handleClickOutside(event) {
      // Проверяем, был ли клик вне области компонента
      if (this.isOrderVisible && !this.$refs.floatingOrder.$el.contains(event.target)) {
        this.closeOrder();
      }
    },
    mounted() {
      // Добавляем слушатель клика при монтировании компонента
      document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
      // Убираем слушатель при размонтировании компонента
      document.removeEventListener('click', this.handleClickOutside);
    },
    scrollToNext(category) {
      let container, currentIndex, cards;

      if (category === 'chocolate') {
        container = this.$refs.chocolateContainer; // Получаем контейнер для шоколадных карточек
        currentIndex = this.currentIndexChocolate; // Текущий индекс для шоколадных карточек
        cards = this.cardsChocolate; // Массив карточек
      } else {
        container = this.$refs.svechiContainer; // Получаем контейнер для свечей
        currentIndex = this.currentIndexSvechi; // Текущий индекс для свечей
        cards = this.cardsSvechi; // Массив карточек
      }

      const cardWidth = container.children[0].offsetWidth; // Ширина первой карточки

      if (currentIndex < cards.length - 1) {
        // Если текущий индекс меньше длины массива карточек
        this[`currentIndex${category.charAt(0).toUpperCase() + category.slice(1)}`]++; // Увеличиваем индекс
        container.scrollBy({left: cardWidth, behavior: 'smooth'}); // Прокручиваем контейнер
      }
    },
    scrollToBack(category) {
      let container, currentIndex;

      if (category === 'chocolate') {
        container = this.$refs.chocolateContainer; // Получаем контейнер для шоколадных карточек
        currentIndex = this.currentIndexChocolate; // Текущий индекс для шоколадных карточек
      } else {
        container = this.$refs.svechiContainer; // Получаем контейнер для свечей
        currentIndex = this.currentIndexSvechi; // Текущий индекс для свечей
      }

      const cardWidth = container.children[0].offsetWidth; // Ширина первой карточки

      if (currentIndex > 0) {
        // Если текущий индекс больше 0
        this[`currentIndex${category.charAt(0).toUpperCase() + category.slice(1)}`]--; // Уменьшаем индекс
        container.scrollBy({left: -cardWidth, behavior: 'smooth'}); // Прокручиваем контейнер назад
      }
    },
    scrollToBlock(blockId) {
      const block = document.getElementById(blockId);
      if (block) {
        block.scrollIntoView({behavior: 'smooth'});
      }
    },
  }
};
</script>
<style scoped>

@media (min-width: 769px) {
  .wisherror {
    width: 70vw;
    position: fixed;
    text-align: center;
    bottom: 20px;
    left: 15vw;
    background-color: white;
    padding: 10px 20px;
    border-radius: 90px;
    animation: fadeOut 2s forwards;
  }

  .otziv {
    width: 40%;
    color: #785E53;
    margin: 26px 20px 0 6vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .otziv p {
    font-size: 32px;
    font-family: Montserrat-light, sans-serif;
    margin-bottom: 50px;
  }

  .otziv p.big_title {
    font-size: 56px;
  }

  .inputs p {
    display: flex;
    align-items: center;
  }

  .agree {
    font-family: "Roboto Light", sans-serif;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }

  .agree p {
    margin-top: 1px;
  }

  #agr {
    height: 20px;
    width: 20px;
    margin-right: 20px;
  }


  .centring_title {
    justify-content: center;
    width: 50vw;
    align-items: center;
    display: flex;
  }

  .videoM {
    display: none;
  }

  .leftscroll {
    display: none;
  }

  .rightscroll {
    display: none;
  }

  .faq_subtitle {
    font-size: 24px;
    color: #717171;
    margin-left: 160px;
    width: 426px;
    margin-top: 24px
  }

  .faq_title {
    font-size: 56px;
    color: #717171;
    margin-left: 160px
  }

  .content_overlay {
    position: relative;
    z-index: 1;
    color: white;
    text-align: center;
  }

  .content_container {
    width: 77.7vw;
    margin-left: 160px;
    margin-right: 160px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .subwrap {
    display: flex;
  }

  .video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw; /* Ширина видео по ширине экрана */
    height: 100vh; /* Высота видео по высоте экрана */
    object-fit: cover; /* Масштабирование видео для заполнения контейнера */
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  .bock1 {
    position: relative;
    width: 100%;
    height: 100vh; /* Высота контейнера по высоте экрана */
    overflow: hidden;
  }

  .header_block {
    margin-left: 60px;
    margin-right: 60px;
    display: flex;
    justify-content: flex-end;
  }

  .name_title {
    margin-top: 155px;
    letter-spacing: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .naming {
    font-size: 72px;
  }

  .nav {
    min-width: 500px;
    font-weight: 600;
    margin-top: 60px;
    font-size: 24px;
    width: 33vw;
    display: flex;
    justify-content: space-between;
  }

  .nav p {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 144px;
    height: 48px;
    background-color: #CAB0A4;
    color: #785E53;
    margin: 0;
    border-radius: 90px;
  }

  .logo {
    width: 210px;
    height: 210px;
    background: black;
  }


  .bock2 {
    background-color: #E5D8D2;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
  }

  /*
  .middle_block {
    height: 60vh;
    max-height: 550px;
    display: flex;
    justify-content: space-between;
  }

  .up_block {
    height: 60vh;
    max-height: 550px;
    display: flex;
    justify-content: space-between;
  }

  .down_block {
    height: 60vh;
    max-height: 550px;
    display: flex;
    justify-content: space-between;
  }
  */
  .blocks {
    min-height: 60vh;
    max-height: 750px;
    display: flex;
    justify-content: space-between;
  }

  .bt2blk {
    border: none;
    border-radius: 100px;
    margin-top: 44px;
    width: 19.2vw;
    height: 48px;
    min-width: 227px;
    font-size: 20px;
    background-color: #CAB0A4;
  }

  .big_title {
    font-family: "Montserrat-med", sans-serif !important;
    font-size: 56px;
    color: #785E53;

  }

  .lit_title {
    padding: 0 15px 0 15px;
    max-width: 662px;
    font-family: Montserrat-light, sans-serif;
    text-align: center;
    margin-top: 26px;
    color: #431D0B;
    font-size: 24px;
  }

  .titling {

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .titling2 {
    width: 11vw;
    margin-right: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .photo_block2 {
    background: url("../public/img/block_svechi.png");
    background-size: cover;
    width: 50vw;
  }

  .photo_block1 {
    background: url("../public/img/block_choco.png");
    background-size: cover;
    width: 50vw;
  }


  .bock3 {
    margin-top: -1px;
    height: fit-content;
    padding-top: 100px;
    padding-bottom: 50px;
    width: 100%;
    background-color: #E5D8D2;
    background-size: cover;
  }

  .assort_ttl {
    margin-left: 160px;
    font-size: 56px;

    color: #431D0B;
  }

  .rzdl {
    margin-top: 36px;
    margin-left: 160px;
    font-size: 48px;
    color: #785E53;
  }

  .content_container {
    margin-top: 50px;
    height: fit-content;
  }

  .content_wrapper {
    display: flex;
    justify-content: center;
  }

  .bock4 {
    margin-top: -1px;
    display: flex;
    padding-bottom: 50px;
    flex-direction: row;
    min-height: 100vh;
    width: 100%;
    background-color: #E5D8D2;
    background-size: cover;
  }

  .inputs {
    margin: 30px auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .thin_input {
    font-size: 14px;
    padding-left: 24px;
    height: 50px;
    margin-top: 16px;
    width: 581px;
    border: none;
    border-radius: 10px;
  }

  .fat_input {
    resize: none;
    padding-top: 16px;
    font-size: 14px;
    padding-left: 24px;
    height: 110px;
    margin-top: 16px;
    width: 581px;
    border: none;
    border-radius: 10px;
  }

  .send_button {
    cursor: pointer;
    margin-top: 30px;
    margin-left: 200px;
    height: 50px;
    width: 260px;
    color: white;
    font-family: "Roboto Light", sans-serif;
    font-weight: bold;
    font-size: 16px;
    border: solid 0;
    border-radius: 10px;
    background-color: #CAB0A4;
  }


  .bock5 {
    margin-top: -1px;
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    width: 100%;
    background-color: #E5D8D2;
    background-size: cover;
  }

  .end_img {
    height: auto;
    width: 50%;
    background-image: url(/public/img/example_end.jpg);
    background-size: cover;
  }

  .abt_ttl {
    width: 40%;
    color: #785E53;
    margin: 26px 50px 0 6vw;
    font-size: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .abt_ttl p {
    font-family: Montserrat-light, sans-serif;
  }

  .lit_title_m {
    display: none;
  }

  .scroll-button {
    display: none;
  }
}

@media (max-width: 768px) {
  .wisherror {
    width: 70vw;
    position: fixed;
    text-align: center;
    bottom: 20px;
    left: auto;
    right: auto;
    z-index: 8000000;
    font-size: 16px;
    background-color: white;
    padding: 10px 20px;
    border-radius: 20px;
    animation: fadeOut 2s forwards;
  }

  .videoM {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    -o-object-fit: cover;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  .video {
    display: none;
  }

  .bock1 {
    position: relative;
    width: 100%;
    height: 100vh; /* Высота контейнера по высоте экрана */
    overflow: hidden;
  }

  .content_overlay {
    display: none;
  }

  .blocks {
    min-height: 50vh;
    max-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .blocks p.big_title {
    font-size: 56px;
  }

  .bock2 {
    min-height: 100vh;
    background-color: #E4D7D1;
    background-size: cover;
  }

  #presents, .rzdl {
    display: none !important;
  }

  .up_block {
    background: url("../public/img/block_choco.png");
    height: 50vh;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .down_block {
    background: url("../public/img/block_svechi.png");
    height: 50vh;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .titling {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .titling2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .big_title {
    font-family: Montserrat-med, sans-serif !important;
    font-weight: 500;
    font-size: 36px;
    color: white;
  }

  .lit_title, .photo_block1, .photo_block2 {
    display: none;
  }

  .lit_title_m {
    width: 85vw;
    text-align: center;
    display: flex;
    font-family: "Montserrat-light", sans-serif;
    color: white;
    font-size: 24px;
    margin: 25px 0 20px 0;
  }

  .bt2blk {
    border-radius: 30px;
    border: none;

    width: 60.7vw;
    height: 48px;
    font-size: 24px;
  }

  .bock3 {
    margin-top: -1px;
    padding-top: 75px;
    width: 100vw;
    height: 100%;
    background-color: #E4D7D1;
  }

  .assort_ttl {
    color: #717171;
    font-size: 42px;
    display: flex;
    justify-content: center;
  }

  .content_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .content_container {
    margin-top: 10px;
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    width: 100vw;
  }

  .rzdl {
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 60px;
    font-size: 32px;

  }

  .content_container::-webkit-scrollbar {
    display: none; /* Скрываем скроллбар */
  }

  .leftscroll {
    position: absolute;
    height: 100%;
    left: 5vw;
    top: 0;
    background-color: transparent;
    border: none;
    z-index: 1003;
  }

  .rightscroll {
    position: absolute;
    height: 100%;
    top: 0;
    right: 5vw;
    background-color: transparent;
    border: none;
    z-index: 1003;
  }

  .bock4 {
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    padding-top: 40px;
    width: 100%;
    background-color: #E4D7D1;
    background-size: cover;
    margin-top: -1px;
  }

  .agree {
    margin-top: 20px;
    width: 100%;
    display: flex;
    color: white;
    flex-direction: row;
  }

  .agree input {
    width: 40px;
    margin-right: 20px;
    height: 40px;
  }

  .inputs {
    width: 85vw;
    margin: 30px auto 30px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .thin_input {
    font-size: 14px;
    padding-left: 24px;
    height: 50px;
    margin-top: 16px;
    width: 80vw;
    border: none;
    border-radius: 10px;
  }

  .fat_input {
    resize: none;
    padding-top: 16px;
    font-size: 14px;
    padding-left: 24px;
    height: 110px;
    margin-top: 16px;
    width: 80vw;
    border: none;
    border-radius: 10px;
  }

  .send_button {
    cursor: pointer;
    margin-top: 30px;
    height: 50px;
    width: 260px;
    color: white;
    font-family: "Roboto Light", sans-serif;
    font-weight: bold;
    font-size: 16px;
    border: solid 0;
    border-radius: 10px;
    background-color: #CAB0A4;
  }

  .faq_title {
    font-size: 56px;
    color: #717171;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .faq_subtitle {
    font-size: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 70.9vw;
    margin-top: 30px;
  }

  .mob_cont {
    display: flex;
    justify-content: center;
  }

  .bock5 {
    margin-top: -1px;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    background-color: #E4D7D1;
    background-size: cover;
  }

  .abt_ttl p {
    color: #785E53;
    text-align: center;
    margin-bottom: 20px;
    font-family: Montserrat-light, sans-serif;
  }

  .abt_ttl {
    margin-bottom: 40px;
    max-width: 90vw;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    align-items: center;
  }
}

.otziv {
  margin: 26px 20px 0 6vw;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.otziv p {
  color: #785E53;
  font-family: Montserrat-light, sans-serif;
  margin-bottom: 30px;
}


</style>
